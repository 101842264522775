export const headerItems = [
    {
        sort_by: 'checkbox',
        styles: 'width: 40px'
    },
    {
        sort_by: 'index',
        styles: 'width: 40px'
    },
    {
        classes: 'custom-table__row-name',
        styles: 'justify-content: center; width: 140px; padding: 0 10px;',
        sort_by: 'name',
        title: 'Name'
    },
    {
        classes: 'custom-table__row-role',
        styles: 'justify-content: flex-start; width: calc(100% - 220px);',
        sort_by: 'permissions',
        title: 'Permissions',
        hide_sort: true
    }
]
