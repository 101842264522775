export const roleFilters = {
    config: {
        rowsPerPage: true,
        paginationPage: true,
        search: true
    },
    lists: {
        fieldsList: [
            {
                value: 'name',
                text: 'Name'
            }
        ]
    }
}
