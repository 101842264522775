<template>
    <custom-table
        :filter_settings="roleFilters"
        :filter_items="filterItems"
        :page_filters="page_filters"
        :filters="filters"
        :headers_items="headerItems"
        :toolbar_settings="toolbarSettings"
        :links="links"
        :loading="loadingTable || !items"
        :content-type="contentType"
        :items="items"
        :options="tableOptions"
        :list="permissionsList"
        @getData="getList($event)"
        @selectAllCheckbox="selectAllCheckbox($event)"
        @action="updateRolePermission"
    />
</template>

<script>
import contentBaseMixin from '@/mixins/content/contentBaseMixin'
import { headerItems } from '@/helpers/constants/role-page/roles-table-header-items'
import { roleFilters } from '@/helpers/filters-settings/role-filters'
import { FilterItems, Filters } from '@/helpers/constants/permissions-page/filters'
import { toolbarSettings } from '@/helpers/constants/permissions-page/toolbar-settings'

import { mapContentListFields } from '@/store/modules/permissions/permissions-list'
import { createNamespacedHelpers } from 'vuex';

import { eventBus } from '@/helpers/event-bus'

import {
    ACTION_GET_ROLES_LIST,
    UPDATE_ROLE_PERMISSIONS
} from '@/store/modules/permissions/permissions-list/action-types'
import { debounce, isEqual } from 'lodash';

const {
    mapActions: mapContentListActions
} = createNamespacedHelpers('permissionsList');

export default {
    name: 'RolePages',
    mixins: [contentBaseMixin],
    data() {
        return {
            tableOptions: {
                filters: true,
                content: true,
                toolbar: true,
                hiddenModals: true
            },
            currentRoute: 'posts-list-roles',
            filters: {}
        }
    },
    computed: {
        ...mapContentListFields([
            'roles',
            'loading',
            'fullPermissions'
        ]),
        headerItems() {
            return headerItems
        },
        allToolbarSettings() {
            return toolbarSettings
        },
        roleFilters() {
            return roleFilters
        },
        filterItems() {
            return FilterItems
        },
        items() {
            return this.roles
        },
        loadingTable() {
            return this.loading
        },
        links() {
            return [
                {
                    to: 'posts-list-roles',
                    title: 'Roles'
                },
                {
                    to: 'posts-list-permission',
                    title: 'Permissions'
                }
            ]
        },
        permissionsList() {
            return this.fullPermissions
        },
        page_filters() {
            return Filters
        }
    },
    watch: {
        '$route.query': debounce(async function (val) {
            if (this.$route.name !== 'posts-list-roles') return
            if (isEqual(this.$route.query, this.filters)) return
            this.filters = {
                ...val
            }
            await this.getList(val)
        }, 50)
    },
    activated() {
        this.getList()
    },
    methods: {
        ...mapContentListActions([
            ACTION_GET_ROLES_LIST,
            UPDATE_ROLE_PERMISSIONS
        ]),
        getList(query = {}) {
            this[ACTION_GET_ROLES_LIST]({ ...query })
        },
        async updateRolePermission(action) {
            await this[UPDATE_ROLE_PERMISSIONS]({ name: action.data.row.name, permissions: action.data.checked.map((item) => item.id) })
            eventBus.$emit('showSnackBar', 'Permissions has been changed successfully!', 'success')
        }
    }
}
</script>

<style lang="scss">
.custom-table__row-role {
    display: flex;
    flex-wrap: wrap;
    .checkox-block {
        width: 100%;
        margin-top: 0px;
        &:nth-child(-n+4) {
            margin-top: 0px;
        }
        &:nth-last-child(-n+4) {
            margin-bottom: 0px;
        }
    }
}
</style>
